// IconArrowLeft.js
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowLeft.module.css';

const IconArrowLeft = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="#112148" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

IconArrowLeft.defaultProps = { className: null };

IconArrowLeft.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrowLeft; // Ensure this is a default export
