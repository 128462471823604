import React, { useState } from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import { Heading, NamedLink, IconEmailSent, InlineTextButton, IconClose, PrimaryButton, IconArrowHead, Modal, SecondaryButton } from '../../components';

import css from './AuthenticationPage.module.css';
import IconArrowLeft from '../../components/IconArrowLeft/IconArrowLeft';
import IconLocation from '../../components/IconLocation/IconLocation';
import classNames from 'classnames';

const EmailVerificationOTP = props => {
  const {
    name,
    email,
    onResendVerificationEmail,
    resendErrorMessage,
    sendVerificationEmailInProgress,
  } = props;

  const [otp, setOtp] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = event => {
    setOtp(event.target.value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
      <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
    </InlineTextButton>
  );

  const fixEmailLink = (
    <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
      <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
    </NamedLink>
  );

  return (
    <div className={css.content}>
      <NamedLink className={css.verifyCloseOTP} name="ProfileSettingsPage">
        <IconArrowLeft />
        <span className={css.closeTextOTP}>
          <FormattedMessage id="AuthenticationPage.verifyEmailCloseOTP" />
        </span>
      </NamedLink>
      <Heading as="h1" rootClassName={css.modalTitle}>
        <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
      </Heading>

      {resendErrorMessage}

      <div className={css.bottomWrapper}>
        <p className={css.modalHelperText}>
          {sendVerificationEmailInProgress ? (
            <FormattedMessage id="AuthenticationPage.sendingEmail" />
          ) : (
            <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
          )}
        </p>
      </div>

      <Modal
        id="otpModal"
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        usePortal
        rootClassName={css.closeIcon}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.modalContent}>
          <IconLocation />
          <h2 className={css.agreementTitle}>
            <FormattedMessage id="AuthenticationPage.agreementTitle" />
          </h2>
          <p className={css.agreementSubheading}>
            <FormattedMessage id="AuthenticationPage.agreementfirstSubheading" />
          </p>
          <p className={css.agreementSubheading}>
            <FormattedMessage id="AuthenticationPage.agreementsecondSubheading" />
          </p>
          <p className={css.agreementSubheadingStrong}>
            <FormattedMessage id="AuthenticationPage.agreementthirdSubheading" />
          </p>
          <PrimaryButton className={css.modalButton}>
            <FormattedMessage id="AuthenticationPage.agreementContinue" />
          </PrimaryButton>
          <SecondaryButton onClick={handleCloseModal} className={css.modalDecline}>
            <FormattedMessage id="AuthenticationPage.agreementDecline" />
          </SecondaryButton>
        </div>
      </Modal>
    </div>
  );
};

export default EmailVerificationOTP;
