// IconLocation.js
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconLocation.module.css';

const IconLocation = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="41" height="56" viewBox="0 0 41 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0798 3.51397C10.9308 3.51397 3.51397 10.9308 3.51397 20.0798C3.51397 21.9761 3.71234 23.5581 4.20517 24.8585C4.44724 25.4972 5.3579 27.1328 6.83579 29.595C7.52804 30.7483 8.29522 32.0038 9.0933 33.3099L9.11256 33.3414C9.91459 34.6539 10.7459 36.0146 11.5458 37.3455C14.3254 41.9704 17.2131 46.5267 19.4022 50.0938C19.7118 50.5983 20.4469 50.5976 20.7555 50.0925C22.9649 46.4769 25.8688 41.8721 28.6211 37.3336C29.5033 35.8788 30.3849 34.4539 31.2132 33.1159L31.2895 32.9927C32.0926 31.6955 32.8404 30.4876 33.5031 29.3924C34.1893 28.2586 34.7608 27.2841 35.1913 26.4908C35.6538 25.6386 35.8431 25.1935 35.8879 25.0508C36.3794 23.486 36.6457 21.8173 36.6457 20.0798C36.6457 10.9308 29.2289 3.51397 20.0798 3.51397ZM39.2404 26.1038C39.8377 24.2021 40.1597 22.1786 40.1597 20.0798C40.1597 8.99004 31.1696 0 20.0798 0C8.99004 0 0 8.99004 0 20.0798C0 22.1786 0.212887 24.2399 0.919259 26.1038C1.48647 27.6004 3.69029 31.207 6.09056 35.1352C6.9011 36.4616 7.73404 37.8247 8.53393 39.1557C12.0803 45.0564 15.7905 51.1749 18.0187 54.8427C18.9578 56.3883 21.1958 56.385 22.1316 54.8374C24.379 51.1206 28.1303 44.9196 31.6257 39.1557C32.5269 37.6697 33.4288 36.2128 34.2819 34.8349C36.7332 30.8753 38.7802 27.569 39.2404 26.1038Z" fill="#112148"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8026 18.2806C25.8026 18.1393 25.7419 18.0047 25.6359 17.9112L20.4057 13.2963C20.2195 13.1321 19.9401 13.1321 19.754 13.2963L14.5237 17.9113C14.4178 18.0047 14.3571 18.1393 14.3571 18.2806V23.8043C14.3571 24.0763 14.5776 24.2968 14.8496 24.2968H25.3101C25.5821 24.2968 25.8026 24.0763 25.8026 23.8043V18.2806ZM11.8856 16.4899C11.6696 16.6805 11.5459 16.9547 11.5459 17.2428V26.104C11.5459 26.6585 11.9954 27.108 12.5499 27.108H27.6098C28.1642 27.108 28.6138 26.6585 28.6138 26.104V17.2428C28.6138 16.9547 28.49 16.6805 28.274 16.4899L20.7441 9.84589C20.3646 9.51101 19.7951 9.51101 19.4156 9.84589L11.8856 16.4899Z" fill="#112148"/>
    </svg>
  );
};

IconLocation.defaultProps = { className: null };

IconLocation.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLocation; // Ensure this is a default export
